/* body scrollbar style */

body{
    background-color: #F7F8FB; 
    margin: 0;
    overflow: hidden !important;
}

body::-webkit-scrollbar{
    width: 4px;
    height: 4px;  
}

body::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid transparent;
}

body::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #ACB5BD;
    border: 1px solid #ACB5BD;
}

body::-webkit-scrollbar-thumb:hover{
    background-color: #495057;
    border: 1px solid #495057;
}  